import { useState } from "react"
import cx from "classnames"
import { usePropsObject } from "@/lib/contexts/appContext"
import fetchLatestArticles from "@/lib/api/fetchLatestArticles"
import Block from "@/components/wrappers/block"
import Article from "@/components/common/article/article"
import Button from "@/common/button/button"
import styles from "./latest-articles.module.css"

export default function LatestArticles({ block }) {
  const { attrs } = block
  const { theme } = usePropsObject()
  const [isFetching, setIsFetching] = useState(false)
  const [allArticles, setArticles] = useState(attrs.articles)
  const [nextPage, setPage] = useState(2)

  if (attrs.articles.length === 0) {
    return null
  }

  const fetchArticlesOnClick = async () => {
    if (isFetching) {
      return
    }

    setIsFetching(true)

    const articlesObj = await fetchLatestArticles(block.attrs.context, nextPage)
    if (articlesObj.articles.length > 0) {
      const prettyArticles = articlesObj.articles.map((article) => {
        return article
      })
      setArticles([...allArticles, ...prettyArticles])
      setPage(nextPage + 1)
    }
    setIsFetching(false)
  }

  const LoadButton = () => {
    if (nextPage > attrs.maxPage) {
      return <div className={styles.noMoreArticles}>Inga fler artiklar</div>
    }

    return (
      <Button
        className={styles.loadMoreButton}
        theme={theme?.accentColor ?? "light-lime"}
        tagName={attrs.button.url ? "a" : ""}
        href={attrs.button.url}
        onClick={fetchArticlesOnClick}
        loading={isFetching}
        disabled={isFetching}>
        {isFetching && <span>Laddar...</span>}
        {!isFetching && attrs.button.label}
      </Button>
    )
  }

  return (
    <Block
      block={block}
      className={cx({ "text-white": theme?.bgColor == "black" })}
      width="full">
      <div className={styles.blockGrid}>
        {allArticles.map((article, index) => (
          <div key={`article-${index}`} className="flex">
            <Article
              className={styles.article}
              item={article}
              showImage={true}
            />
          </div>
        ))}
      </div>
      <LoadButton />
    </Block>
  )
}
